@import "jlu_colors";

$font-size-base: 0.8rem !default; // JLU default is around `14px`
$h1-font-size: $font-size-base * 1.7;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base * 1;

@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.plone";

// JLU specific component color usage (redefinitions)
// we need bootstrap color vriables already defined here, so that we can use them

$colorBlue: #eaf7ff;
$colorBodyBg: $white;
$colorBorder: #8cacbb;
$colorDiscreet: #666666;
$grayLight: #d1d1d1;
$colorHinweisBg: #ffce7b;
$colorLink: #007bb1;
$colorNavigation: #eaeaea;
$colorNavigationLink: #222222;
$colorText: #222222;
$colorTextDark: #444444;
$colorTableDark: #dfdfdf;
$colorTableHeader: #d5d5d5;
$colorTableLight: #f0f0f0;
$colorDotted: #c1bfbf;
$colorDottedPortlet: #b2b2b2;
$colorBoxShadow: #888888;
$colorBackgroundLight: #fafafa;
$colorInputBorder: #49a4e5;
$ColorDarkRed: #d33f2e;

$state-draft-color: #ffce7b !default; // lime-yellow //draft is visible
$state-pending-color: #e2e721 !default; // orange
$state-private-color: #ff0a3f !default; // red
$state-internal-color: #fab82a !default; // is draft
$state-internally-published-color: #883dfa !default; // is intranet
$plone-link-color: $colorLink !default;
$plone-link-color-on-dark: #ffffff !default;
//if you need different contrast
$plone-link-color-on-grey: #086ca3 !default; //valid wcag 2.0
$plone-link-hover-color: darken($plone-link-color, 15%) !default;

// bootstrap settings:
$enable-grid-classes: false;


$plone-link-color: $colorLink !default; //plone blue made slightly darker for wcag 2.0

// Bootstrap - imported with --load-path=node_modules
@import "bootstrap/scss/bootstrap";

$offcanvas-horizontal-width: 400px;
@import "@plone/plonetheme-barceloneta-base/scss/alerts";
@import "@plone/plonetheme-barceloneta-base/scss/login";
@import "@plone/plonetheme-barceloneta-base/scss/toolbar";
@import "@plone/plonetheme-barceloneta-base/scss/content_base";

/* Dimensions.
  ===========
*/
$grid_gap: 10px;
// $height_nav_base: 3.8em;
// $height_nav_factor: 1;
// $height_tablet_header: 5.2em;
// $height_nav: $height_nav_base * $height_nav_factor;

@import "plone_reset";
@import "mixins";
@import "portlets";
@import "addons";
@import "datatables";

// JLU specific styles
@import "jlu";
@import "jlu_content";

@import "mobile";
@import "tablet";
@import "desktop";
@import "tinymce-formats";

:root {
  --plone-toolbar-width: 260px;
  --bs-body-font-family: "Verdana", Helvetica, Arial, sans-serif;
  --globalnav-line-height: 1.24em;
  --globalnav-font-size: 1.05em;
  // --plone-toolbar-link-color: var(--plone-link-color);
  // --plone-toolbar-link-hover-color: var(--plone-link-hover-color);
}