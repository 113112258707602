body.viewpermission-view,
body.viewpermission-none {
  .cp_contentdesktop {
    display: none;
  }

  .cpcell {
    display: table-cell;
    vertical-align: top;
  }
  .cptable {
    display: table;
    margin-bottom: 1.2em;
    width: 100%;
  }
  .cprow {
    display: table-row;
  }

  &.portaltype-entrypage{
    .entrypage--text{
      margin: 2em 0;
    }
    .entrypage--collections{
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      margin: 2em 0;
      > div {
        flex: 1 1 1em;
        min-width: 35ch;
      }
    }
  }

  .portletHeader.topic-list,
  .portletHeader.rss-right,
  .portletHeaderHtml.rss-right{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    svg, img{
      padding-bottom: 0.1em;
    }
  }

  .form-field--h-small textarea.form-control{
    min-height: 6ch !important;
  }
  .form-field--h-medium textarea.form-control{
    min-height: 22em !important;
  }
  .form-field--h-large textarea.form-control{
    min-height: 40em !important;
  }

  #slider {
    // max-width: 100% !important;
    .slider-list{
      margin-top: 0;
    }
  }

  .slider-container, #slider-container {
    border: 1px solid #F2F2F2 !important;
    border-radius: 2px;
    box-shadow: 3px 3px 10px #F2F2F2;
    // padding: 6px;
    overflow: hidden;
  }
  .slideroverlay .uniblau a {
    color: #F7931E !important;
    text-decoration: none;
  }
  .slideroverlay {
    box-sizing: content-box;
    font-size: 0.94em;
    line-height: 1.415em;
    padding: 0;
    > p{
      padding: 0.6em !important;
    }
  }
  #easySlider-goToButtons{
    top: -28px;
    line-height: 1em;
    // display: inline-block;
    float: right;
    .easySlider-goToBullets a{
      line-height: 0.5em;
    }
  }
}
