// content related styles here.


@media (max-width: 575px) {
  body.viewpermission-view,
  body.viewpermission-none,
  body.mce-content-body {
    .card-hz-right.card,
    .card-hz-left.card {
      flex-direction: column;
      img {
        border-radius: 0.25rem 0.25em 0 0 !important;
        aspect-ratio: 3/2;
        object-fit: cover;
      }
    }
  }
}

.image-right {
  @extend .mx-auto;
  @extend .my-2;

  &.picture-variant-large {
    @extend .float-xxl-end;
    @extend .ms-xxl-2;
  }

  &.picture-variant-medium {
    @extend .float-xl-end;
    @extend .ms-xl-2;
  }

  &.picture-variant-small {
    @extend .float-md-end;
    @extend .ms-md-2;
  }

  &.picture-variant-mini {
    @extend .float-md-end;
    @extend .ms-md-2;
  }
}

.image-left {
  @extend .mx-auto;
  @extend .my-2;

  &.picture-variant-large {
    @extend .float-xxl-start;
    @extend .me-xxl-2;
  }

  &.picture-variant-medium {
    @extend .float-xl-start;
    @extend .me-xl-2;
  }

  &.picture-variant-small {
    @extend .float-md-start;
    @extend .me-md-2;
  }

  &.picture-variant-mini {
    @extend .float-md-start;
    @extend .me-md-2;
  }
}

.jlu-boxes{
  p.p-box {
    padding: 1em;
    margin: 0.5em 0;
    border-radius: 0.1em;
    box-shadow: 0 0 0.1em 0 #888;
  }

  p.discreet {
    @extend .p-box;
    color: #666;
    font-size: 85%;
    border-radius: 0;
    box-shadow: none;
    a {
      color: #666;
    }
  }
  p.hinweisdefault {
    @extend .p-box;
    background: #ffce7b;
    color: #000;
    a {
      color: #165A97;
    }
  }
  p.grau {
    @extend .p-box;
    background: #f8f9fa;
    color: #000;
    a {
      color: #165A97;
    }
  }
  p.greendefault {
    @extend .p-box;
    background: #e5f0cf;
    color: #000;
    a {
      color: #165A97;
    }
  }
  p.bluedefault {
    @extend .p-box;
    background: #eaf7ff;
    color: #000;
    a {
      color: #165A97;
    }
  }
  p.uniblau {
    @extend .p-box;
    background: #165a97;
    color: #fff;
    a {
      color: #fff;
    }
  }
  p.callout {
    @extend .p-box;
    box-shadow: 0 0 1em 0 #888;
  }
}


.jlu-content-core {
  .card-single {
    width: 18rem;
    .card-title {
      font-weight: bold;
      color: $colorTextDark;
    }
  }
  .card-box {
    --min: 21ch;
    --gap: 3rem;
    display: grid;
    gap: min(var(--gap), 2vw);
    grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--min)), 1fr));
    justify-items: center;
    margin: 2em 0;
  }

  .card-hz-left.card {
    flex-direction: row;
    img {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }
  }

  .card-hz-right.card {
    flex-direction: row-reverse;
    img {
      border-bottom-right-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
  }

  .card-title,
  .cpcell h3 {
    @extend .nobox;
  }

  &.template-sublisting_view {
    .card {
      --bs-card-border-width: 0px;
      --bs-card-border-color: transparent;
      --bs-card-cap-bg: transparent;
      --bs-card-cap-padding-y: 0;
      --bs-card-cap-padding-x: 0;

      img {
        margin-bottom: 0.8em;
        border-radius: 0;
      }
    }
    .card-body {
      padding: 0 0 0.8em 0;
    }
    .card-box {
      --min: 21ch;
      --gap: 1.5em;
      display: grid;
      gap: min(var(--gap) + 1.5em, 3vw) min(var(--gap), 2vw);
      grid-template-columns: repeat(
        auto-fit,
        minmax(min(100%, var(--min)), 1fr)
      );
      margin: 2em 0;
    }
  }

  @extend .jlu-boxes;

  .jlu-buttons{
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin: 1em 0;
  }

  .pullquote {
    background: $colorBodyBg
      url("++theme++jlu-giessen-plone-6-theme/images/apostr1.gif") top left
      no-repeat;
    color: $colorText;
    float: right;
    font-size: 130%;
    font-weight: normal;
    letter-spacing: 0 !important;
    line-height: 160%;
    margin: 0 0 1em 2em;
    padding: 0;
    text-indent: 40px;
    width: 35%;
  }
  .jlu-hero {
    display: flex;
    flex-direction: column;
    h2, h3, h4{
      @extend .text-blue;
      @extend .nobox;
    }
    h2 {
      font-size: 1.8em;
      margin: min(2em, 3vw) 0;
    }
    h3 {
      font-size: 1.6em;
      margin: min(1.6em, 2.6vw) 0;
    }
    h4 {
      font-size: 1.4em;
      margin: min(1.4em, 2.5vw) 0;
    }
    p {
      max-width: min(92vw, 80%);
      align-self: center;
    }
  }

  li {
    display: list-item;
    margin: 0 0 0.28em;
    &.nav-item{
      margin: 0;
    }
  }
  ol {
    list-style-type: decimal;
    // margin-left: 1.8em;
    margin-top: 0.8em;
    li {
      ol {
        list-style-type: lower-alpha;
      }
    }
    &.listTypeUpperRoman {
      li {
        border-bottom: 1px dotted $colorDottedPortlet;
        list-style-type: upper-roman;
        &::before {
          content: none;
        }
      }
    }
    &.listTypeLowerRoman {
      li {
        border-bottom: 1px dotted $colorDottedPortlet;
        list-style-type: lower-roman;
        &::before {
          content: none;
        }
      }
    }
    &.listTypeLowerAlpha {
      li {
        border-bottom: 1px dotted $colorDottedPortlet;
        list-style-type: lower-alpha;
        &::before {
          content: none;
        }
      }
    }
    &.listTypeUpperAlpha {
      li {
        border-bottom: 1px dotted $colorDottedPortlet;
        list-style-type: upper-alpha;
        &::before {
          content: none;
        }
      }
    }
  }
  ul {
    // margin-left: 1.8em;
    margin-top: 0.8em;
    list-style: none;
    &.dropdown-menu {
      li {
        list-style-type: none;
        &::before {
          content: none;
        }
      }
    }
    li {
      &::before {
        content: "■";
        font-family: arial;
        display: block;
        right: 18px;
        max-height: 0;
        position: relative;
        color: $blue;
      }
    }
    &.listTypeCircle {
      li {
        list-style-type: none;
        &::before {
          content: "○";
          display: block;
          left: -18px;
          max-height: 0;
          position: relative;
          color: $blue;
          top: 1px;
        }
      }
    }
    &.listTypeSquare {
      li {
        list-style-type: none;
        &::before {
          content: "■";
          display: block;
          left: -18px;
          max-height: 0;
          position: relative;
          color: $blue;
        }
      }
    }
    &.listTypeDisc {
      li {
        list-style-type: none;
        &::before {
          content: "●";
          display: block;
          left: -18px;
          max-height: 0;
          position: relative;
          color: $blue;
        }
      }
    }
    &.listTypeRightArrow {
      li {
        list-style-type: none;
        &::before {
          content: "➜";
          display: block;
          left: -18px;
          max-height: 0;
          position: relative;
          color: $blue;
        }
      }
    }
    &.listTypeDoubleAngle {
      li {
        list-style-type: none;
        &::before {
          content: "\00BB";
          font-family: verdana;
          display: block;
          left: -18px;
          max-height: 0;
          position: relative;
          color: $blue;
        }
      }
    }
    &.ListTypeLine {
      li {
        border-bottom: 1px solid $colorBorder;
        list-style-type: none;
        &::before {
          content: none;
        }
      }
    }
    &.ListTypeDotted {
      li {
        border-bottom: 1px dotted $colorDottedPortlet;
        list-style-type: none;
        &::before {
          content: none;
        }
      }
    }
  }

  ul.tabbernav li {
    list-style: none;
    display: inline;
    @extend .nobox;
  }

  .tab-content{
    padding-top: 0.5em;
  }

  /* @group Data tables */

  // table.invisible {
  //   visibility: visible !important;
  // }

  // bootstrap fixes for tinymce tables having td's not th in header
  .table {
    thead{
      td{
        font-weight: bold;
      }
    }
  }

  table.echt {
    background-color: $colorBackgroundLight;
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-style: initial;
    }
  }
  table.echt th {
    background-color: $colorTableHeader;
    border-width: medium;
    margin: 0.75ex;
    padding: 0.75ex;
    text-align: center;
  }
  table.echt td {
    background-color: $colorTableDark;
    border-width: medium;
    margin: 0.75ex;
    padding: 0.75ex;
  }

  table.listing,
  table.plain {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }
  table.dotted td,
  table.dotted th,
  table.line td,
  table.line th,
  table.echt td,
  table.echt th,
  table.plain td,
  table.plain th,
  table.listing td,
  table.listing th {
    padding: 0.26em 0.6em;
    vertical-align: top;
  }
  table.dotted td[valign="bottom"] {
    vertical-align: bottom;
  }
  table.dotted td[valign="middle"] {
    vertical-align: middle;
  }
  table.line td[valign="bottom"] {
    vertical-align: bottom;
  }
  table.line td[valign="middle"] {
    vertical-align: middle;
  }
  table.echt td[valign="bottom"] {
    vertical-align: bottom;
  }
  table.echt td[valign="middle"] {
    vertical-align: middle;
  }
  table.plain td[valign="bottom"] {
    vertical-align: bottom;
  }
  table.plain td[valign="middle"] {
    vertical-align: middle;
  }
  table.listing td[valign="bottom"] {
    vertical-align: bottom;
  }
  table.listing td[valign="middle"] {
    vertical-align: middle;
  }
  // table.invisible td,
  // table.invisible th {
  //   padding: 0.26em 1.6em 0.26em 0;
  //   vertical-align: top;
  // }
  table.listing td.listingCheckbox,
  table.listing th.listingCheckbox {
    text-align: center;
  }
  table.dotted {
    border-collapse: collapse;
    width: 100%;
  }
  table.dotted tr {
    border-bottom: 0.1em dotted #96969d;
  }

  table.line {
    border-collapse: collapse;
    width: 100%;
  }
  table.line tr {
    border-bottom: 0.1em solid #8cacbb;
  }
  table.echt {
    background-color: $colorBackgroundLight;
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
  }
  table.echt th {
    background-color: $colorTableHeader;
    border-width: medium;
    margin: 0.75ex;
    padding: 0.75ex;
    text-align: center;
  }
  table.echt td {
    background-color: $colorTableDark;
    border-width: medium;
    margin: 0.75ex;
    padding: 0.75ex;
  }
  table.plain th,
  table.plain td {
    border: 1px solid #dddddd;
  }
  table.plain {
    margin: 1em 0;
    padding-bottom: 0.2em;
  }
  table.plain thead td,
  table.plain tbody th {
    font-weight: bold;
  }
  table.plain tbody tr {
    text-align: left;
  }

  table.plain tbody tr td {
    background: transparent;
  }
  table.listing {
    border-bottom: 1px solid #8cacbb;
    border-collapse: collapse;
    border-left: 1px solid #8cacbb;
    border-top: 1px solid #8cacbb;
    margin: 1em 0;
  }

  table.listing .top {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  table.listing thead tr td,
  table.listing tr th {
    background: none repeat scroll 0 0 #dee7ec;
    border: 0.1em solid #8cacbb;
    border-style: solid solid none none;
  }
  table.listing thead td,
  table.listing tbody th {
    font-weight: bold;
  }
  table.listing tbody tr {
    text-align: left;
  }
  table.listing tbody tr:nth-child(even) {
    background: $colorTableLight;
  }
  table.listing tbody tr td {
    background: transparent;
  }
  table.listing td {
    border-right: 1px solid #8cacbb;
  }
  table.listing td.draggable {
    text-shadow: White 1px 1px 1px;
    background: #ddd !important;
    border-bottom: 1px solid #8cacbb;
    padding: 0.4em 0.4em 0 0.5em;
  }
  /* Vertical table data listings */
  table.vertical td {
    border-top: 0.1em solid #8cacbb;
    padding: 0.26em 0.6em;
  }
  table.vertical tbody tr:nth-child(even) {
    background: transparent;
  }
  table.vertical tbody tr:nth-child(even) td {
    background: transparent;
  }

  /* Grid table listings */
  table.grid thead tr td,
  table.grid tr th {
    background: none repeat scroll 0 0 #dddddd;
    border-style: solid solid none none;
    border: 0.1em solid #e7e7e7;
    border-width: 0.1em;
  }
  table.grid td,
  table.grid th {
    border: 1px solid #e7e7e7;
    padding: 0.26em 0.6em;
  }

  .accordion h2,
  .accordion h3,
  .accordion h4,
  .accordion h5,
  .accordion h6{
    &::before {
      content: none;
    }

  }
}


body.viewpermission-view,
body.viewpermission-none{
  #content-core,
  #content {
    @extend .jlu-content-core;
    @extend .jlu-boxes;
  }

}

.mce-content-body{
  @extend .jlu-content-core;
}

.pagination li::before{
  content: none !important;
}
