/* Layout adjustments for portlets.
   ================================
*/

body.viewpermission-view,
body.viewpermission-none {
  fieldset {
    border-top: 1px solid #8cacbb !important;
    line-height: 1.5em;
    margin: 1em 0;
    padding: 0 1em 1em;
    width: auto;
  }
  legend{
    font-size: medium;
    float: none;
    width: auto;
    padding: 0.4em;
  }
  .portlet {
    border: none;
    margin-right: 0;
    .card-header {
      background: transparent;
      font-weight: bold;
      margin-bottom: 0.4em;
      padding-bottom: 0.2em;
      border-bottom: 0.1em dotted #B2B2B2;
      .viewlet-rss-link {
        // @include icons-sprite(rss);
        color: rgba(0, 0, 0, 0);
        float: right;
        height: 14px;
        width: 14px;
        margin-right: 4px;
        overflow: hidden;
      }
    }
    .card-body {
      clear: both;
      margin-bottom: 0.6em;
      display: block;
    }
    .card-footer {
      display: block;
      font-size: 85%;
      text-align: right;
      background: transparent;
      border-top: 0;
    }
  }

  /* NAVIGATION PORTLET */

  .portletNavigationTree,
  #footer-wrapper .portletNavigationTree {
    margin-bottom: 1.4em;
    ul {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      display: inline;
    }
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      display: block;
      a {
        text-decoration: none;
        color: $colorNavigationLink;
        padding-left: 0.4em;
        border: none;
        display: block;
        &:focus,
        &:active {
          border-bottom: 1px solid $ColorDarkRed;
          text-decoration: none;
        }
        &.state-private {
          color: $state-private-color;
        }
        &.state-pending {
          color: $state-pending-color;
        }
        &.state-draft {
          color: $state-draft-color;
        }
      }
      &.navTreeTopNode {
        background: $blue;
        color: $colorBodyBg;
        font-weight: bold;
        a {
          color: $colorBodyBg;
          text-decoration: none;
        }
      }
      &::before {
        display: none;
      }
    }
    .navTreeCurrentItem,
    .navTreeItem a:hover {
      background-color: $blue;
      color: #fff;
      &.state-private {
        color: $state-private-color;
      }
      &.state-pending {
        color: $state-pending-color;
      }
      &.state-draft {
        color: $state-draft-color;
      }
    }

    ul.navTreeLevel0 {
      > li.navTreeItem {
        border-bottom: 0.1em dotted $colorDotted;
        a {
          padding-bottom: 0.2em;
          padding-top: 0.4em;
        }
        img {
          display: none;
        }
      }
    }

    ul.navTreeLevel1 > li.navTreeItem a {
      padding-left: 1.2em;
    }
    ul.navTreeLevel2 > li.navTreeItem a {
      padding-left: 2.2em;
    }
    ul.navTreeLevel3 > li.navTreeItem a {
      padding-left: 3.2em;
    }
    ul.navTreeLevel4 > li.navTreeItem a {
      padding-left: 4.2em;
    }
    ul.navTreeLevel5 > li.navTreeItem a {
      padding-left: 5.2em;
    }
    ul.navTreeLevel6 > li.navTreeItem a {
      padding-left: 6.2em;
    }
    ul.navTreeLevel7 > li.navTreeItem a {
      padding-left: 7.2em;
    }
    ul.navTreeLevel8 > li.navTreeItem a {
      padding-left: 8.2em;
    }
    ul.navTreeLevel9 > li.navTreeItem a {
      padding-left: 9.2em;
    }
    ul.navTreeLevel10 > li.navTreeItem a {
      padding-left: 10.2em;
    }
  }
  /* NAVIGATION PORTLET END */

  /* CP Portlets */
  .portletWrapper {
    margin-bottom: 1em;
    padding: 1px 1px 0px 2px;
    div.viewlet {
      dl.portlet {
        margin-bottom: 1.2em;
      }
    }
  }

  .defaultPortletWrapper,
  .notitlePortletWrapper,
  .portletStaticText,
  .portletTab {
    .portletHeader,
    .portletHeaderHtml {
      border-bottom: 0.1em dotted $colorDottedPortlet;
    }
    dd.even {
      background: $gray;
    }
  }
  /* Colored portlets. */
  .grayPortletWrapper dl {
    @include coloredBox(colorText, $gray);
  }
  .bluePortletWrapper dl {
    @include coloredBox(colorText, $colorBlue);
  }
  .greenPortletWrapper dl {
    @include coloredBox(colorText, $green);
  }
  .hinweisPortletWrapper dl {
    border: 1px solid #ffa500;
    @include coloredBox(colorText, $colorHinweisBg);
  }
  .uniblauPortletWrapper dl {
    @include darkColoredBox(colorText, $blue);
  }
  .roundedPortletWrapper dl {
    @include coloredBox(colorText, $yellow);
  }
  /* CP Portlets END */
}
